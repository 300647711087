import * as React from "react";
import Layout from "../components/Layout";

import Obfuscate from "react-obfuscate";

const AboutPage = () => {
  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title"> Datenschutz </h1>

          <div className="content">
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>{" "}
            <p>
              Die folgenden Hinweise geben einen einfachen &Uuml;berblick
              dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn
              Sie diese Website besuchen. Personenbezogene Daten sind alle
              Daten, mit denen Sie pers&ouml;nlich identifiziert werden
              k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz
              entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
              Datenschutzerkl&auml;rung.
            </p>
            <h3>Datenerfassung auf dieser Website</h3>{" "}
            <h4>
              Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
              Website?
            </h4>{" "}
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
              Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in
              dieser Datenschutzerkl&auml;rung entnehmen.
            </p>{" "}
            <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
              Sie in ein Kontaktformular eingeben.
            </p>{" "}
            <p>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem
              oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
              erfolgt automatisch, sobald Sie diese Website betreten.
            </p>{" "}
            <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{" "}
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
              k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>{" "}
            <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{" "}
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
              Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
              Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
              verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
              erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
              f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
              Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
              Aufsichtsbeh&ouml;rde zu.
            </p>{" "}
            <p>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen
              Sie sich jederzeit an uns wenden.
            </p>
            <h2>2. Hosting</h2>
            <h3>Externes Hosting</h3>{" "}
            <p>
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Websitezugriffe und sonstige Daten, die &uuml;ber eine Website
              generiert werden, handeln.
            </p>{" "}
            <p>
              Der Einsatz des Hosters erfolgt zum Zwecke der
              Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
              bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
              einer sicheren, schnellen und effizienten Bereitstellung unseres
              Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs.
              1 lit. f DSGVO).
            </p>{" "}
            <p>
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </p>{" "}
            <p>Wir setzen folgenden Hoster ein:</p>
            <p>
              netcup GmbH
              <br />
              Daimlerstraße 25
              <br />
              76185 Karlsruhe
            </p>
            <h4>Auftragsverarbeitung</h4>{" "}
            <p>
              Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) mit
              dem oben genannten Anbieter geschlossen. Hierbei handelt es sich
              um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
              gew&auml;hrleistet, dass dieser die personenbezogenen Daten
              unserer Websitebesucher nur nach unseren Weisungen und unter
              Einhaltung der DSGVO verarbeitet.
            </p>
            <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
            <h3>Datenschutz</h3>{" "}
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer
              pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend den
              gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerkl&auml;rung.
            </p>{" "}
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
              Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche
              Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
              erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
            </p>{" "}
            <p>
              Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
              (z.&nbsp;B. bei der Kommunikation per E-Mail)
              Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
            </p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
            <p>
              Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
              dieser Website ist:
            </p>{" "}
            <p>
              Schützengilde Königreich Flieden e.V.
              <br />
              Alte Strasse 61
              <br />
              Schützenhaus
              <br />
              36103 Flieden
              <br />
              Hessen
            </p>
            <p>
              Tel.: <Obfuscate tel="0 66 55 74 92 85" />
              <br />
              E-Mail: <Obfuscate email="vorstand@sgi-flieden.de" />
              <br />
              Website: <a href="https://sgi-flieden.de">sgi-flieden.de</a>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Vertreten wird die Schützengilde Königreich Flieden e.V. durch:
              </span>
              <h5>1. Vorsitzender</h5>
              <p>
                Fredy Schüßler
                <br />
                Kolpingstr. 25
                <br />
                36119 Neuhof
                <br />
                <Obfuscate tel="06655 74 92 85" />
              </p>
              <h5>2. Vorsitzender</h5>
              <p>
                Erwin Haubner
                <br />
                Bornweg 37
                <br />
                63599 Biebergemünd
                <br />
                <Obfuscate tel="06050 90 74 63" />
              </p>
            </p>
            <p>
              Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
              Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten
              (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
            </p>
            <h3>Hinweis zum Datenschutzbeauftragten</h3>{" "}
            <p>
              Der Datenschutzbeauftragte der für die Verarbeitung
              Verantwortlichen ist:
            </p>
            <p>
              Anton Haubner
              <br />
              Hermannstraße 27
              <br />
              Darmstadt 64285
              <br />
              Hessen
              <br />
              Tel: <Obfuscate tel="0160 661 68 60" />
              <br />
              E-Mail: <Obfuscate email="datenschutz@sgi-flieden.de" />
            </p>
            <p>
              Jede betroffene Person kann sich jederzeit bei allen Fragen und
              Anregungen zum Datenschutz direkt an unseren
              Datenschutzbeauftragten wenden.
            </p>
            <h3>Speicherdauer</h3>{" "}
            <p>
              Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
              speziellere Speicherdauer genannt wurde, verbleiben Ihre
              personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
              Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
              L&ouml;schersuchen geltend machen oder eine Einwilligung zur
              Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
              sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde
              f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
              (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
              im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall
              dieser Gr&uuml;nde.
            </p>
            <h3>
              Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
            </h3>{" "}
            <p>
              Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
              USA oder sonstigen datenschutzrechtlich nicht sicheren
              Drittstaaten. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
              personenbezogene Daten in diese Drittstaaten &uuml;bertragen und
              dort verarbeitet werden. Wir weisen darauf hin, dass in diesen
              L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau
              garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
              verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
              herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
              vorgehen k&ouml;nnten. Es kann daher nicht ausgeschlossen werden,
              dass US-Beh&ouml;rden (z.&nbsp;B. Geheimdienste) Ihre auf
              US-Servern befindlichen Daten zu &Uuml;berwachungszwecken
              verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
              diese Verarbeitungst&auml;tigkeiten keinen Einfluss.
            </p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
            <p>
              Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
              ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
              eine bereits erteilte Einwilligung jederzeit widerrufen. Die
              Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
              Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </p>
            <h3>
              Widerspruchsrecht gegen die Datenerhebung in besonderen
              F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h3>{" "}
            <p>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
              GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
              GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
              EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
              GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
              DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
              DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
              WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
              VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
              SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
              &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
              AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
              (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
            </p>{" "}
            <p>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
            <h3>
              Beschwerde&shy;recht bei der zust&auml;ndigen
              Aufsichts&shy;beh&ouml;rde
            </h3>{" "}
            <p>
              Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
              Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
              insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
              Aufenthalts, ihres Arbeitsplatzes oder des Orts des
              mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
              unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{" "}
            <p>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen,
              maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie
              die direkte &Uuml;bertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </p>
            <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{" "}
            <p>
              Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
              &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
              Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
              senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
              verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
              Adresszeile des Browsers von &bdquo;http://&ldquo; auf
              &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>{" "}
            <p>
              Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
              k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von
              Dritten mitgelesen werden.
            </p>
            <h3>Auskunft, L&ouml;schung und Berichtigung</h3>{" "}
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
              Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten
              k&ouml;nnen Sie sich jederzeit an uns wenden.
            </p>
            <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{" "}
            <p>
              Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
              jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
              Verarbeitung besteht in folgenden F&auml;llen:
            </p>{" "}
            <ul>
              {" "}
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
                der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{" "}
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt
                der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
                verlangen.
              </li>{" "}
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
                Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
                Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{" "}
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                Interessen vorgenommen werden. Solange noch nicht feststeht,
                wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                Daten zu verlangen.
              </li>{" "}
            </ul>{" "}
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
              ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
              oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
              nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
              eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen
              Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht
              ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
              ausdr&uuml;cklich angeforderter Werbung und
              Informationsmaterialien wird hiermit widersprochen. Die Betreiber
              der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im
              Falle der unverlangten Zusendung von Werbeinformationen, etwa
              durch Spam-E-Mails, vor.
            </p>
            <h2>4. Datenerfassung auf dieser Website</h2>
            <h3>Server-Log-Dateien</h3>{" "}
            <p>
              Unsere Seiten erheben und speichern automatisch Informationen in
              so genannten Server-Log-Dateien, die Ihr Browser automatisch an
              uns &uuml;bermittelt. Dies sind:
            </p>{" "}
            <ul>
              {" "}
              <li>Browsertyp und Browserversion</li>{" "}
              <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
              <li>Hostname des zugreifenden Rechners</li>{" "}
              <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
            </ul>{" "}
            <p>
              Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
              wird nicht vorgenommen.
            </p>{" "}
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files
              erfasst werden.
            </p>
            <p>
              Da unsere Seiten über die Infrastruktur und Systeme unseres
              Hosters (siehe 2. Hosting) bereitgestellt werden, erfasst auch
              dieser ihre Verbindungsdaten (IP Adresse, Zugriffszeitpunkt) bei
              einem Zugriff auf unsere Seiten, jedoch nur soweit, wie dies
              gesetzlich erforderlich ist. Für genaue Auskünfte über die
              Datenerfassung innerhalb der Systeme unseres Hosters wenden Sie
              sich bitte an <Obfuscate email="mail@netcup.de" />.
            </p>
            <h3>Terminbuchungssystem</h3>{" "}
            <p>
              Wir bieten über unseren Webauftritt ein Online-Buchungssystem für
              Schießtermine an. Dort müssen Sie für die Buchung eines Termins
              die folgenden Daten angeben:
            </p>
            <ul>
              <li>Ihr Vor- und Nachname</li>
              <li>Ggf. die Vor- und Nachnamen ihrer Begleitpersonen</li>
              <li>
                Der zu buchende Termin und die gebuchte Resource (z.B.
                Luftgewehrstand)
              </li>
              <li>Ihre E-Mail Adresse</li>
            </ul>
            <p>
              Rechtsgrundlage für die Verarbeitung dieser Daten sind Art. 6 Abs.
              1 lit. a und f DSGVO. Wir speichern diese Daten zu dem Zweck der
              Organisation Ihres Termins. Ihre E-Mail Adresse wird nur
              gespeichert um Ihnen eine elektronische Bestätigung Ihres Termins
              zuzusenden sowie für die Kontaktaufnahme bei Problemen mit Ihrem
              Termin (z.B. bei Absage).
            </p>
            <p>
              Ihre angegebenen Daten werden bis zu einem Tag nach dem Termin
              gespeichert und dann automatisch gelöscht.
            </p>
            <p>
              Die Daten werden nur eingesehen durch den Vorstand, die
              Schießmeister, die Standaufsichten am Tag des Termins sowie eine
              begrenzte Anzahl Mitglieder die mit der Wartung des
              Buchungssystems beauftragt sind.
            </p>
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{" "}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde.
            </p>{" "}
            <p>
              Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
              verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
              die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
              abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
              gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
              Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
            </p>
            <h2>5. Plugins und Tools</h2>
            <h3>Google Maps</h3>{" "}
            <p>
              Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
              Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </p>{" "}
            <p>
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP-Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA &uuml;bertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Daten&uuml;bertragung. Wenn Google Maps aktiviert ist, kann
              Google zum Zwecke der einheitlichen Darstellung der Schriftarten
              Google Web Fonts verwenden. Beim Aufruf von Google Maps l&auml;dt
              Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache,
              um Texte und Schriftarten korrekt anzuzeigen.
            </p>{" "}
            <p>
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer Online-Angebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>{" "}
            <p>
              Die Daten&uuml;bertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
              finden Sie hier:{" "}
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/gdprcontrollerterms/
              </a>{" "}
              und{" "}
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
              </a>
              .
            </p>{" "}
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerkl&auml;rung von Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h3>Google Calendar</h3>
            <p>
              In unserem Internetauftritt setzen wir
              Google&nbsp;Calendar&nbsp;ein um über Veranstaltungen des Vereins
              und Termine für Mitglieder (Arbeitseinsätze) zu informieren. Es
              handelt sich hierbei um einen weiteren Dienst der Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              nachfolgend nur „Google“ genannt.
            </p>
            <p>
              Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US
              Privacy Shield“)
            </p>
            <p>
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
            </p>
            <p>
              garantiert Google, dass die Datenschutzvorgaben der EU auch bei
              der Verarbeitung von Daten in den USA eingehalten werden.
            </p>
            <p>
              Um über Veranstaltungen des Vereins und Termine für Mitglieder
              (Arbeitseinsätze) zu informieren, wird bei Aufruf bestimmter
              Unterseiten unseres Internetauftritts eine Verbindung zu
              Google-Servern aufgebaut, welche sich ggf. in den USA befinden.
              Dieser Verbindungsaufbau erfolgt jedoch erst, nachdem der Besucher
              des Internetauftritts dem Verbindungsaufbau explizit zustimmt
              (Zwei-Klick&nbsp;Ansatz).
            </p>
            <p>
              Sofern Sie die in unseren Internetauftritt eingebundene Komponente
              Google&nbsp;Calendar aufrufen,&nbsp;kann Google über Ihren
              Internet-Browser ein Cookie auf Ihrem Endgerät speichern sowie
              Nutzereinstellungen und -daten speichern und verarbeiten. Hierbei
              können wir nicht ausschließen, dass Google Server in den USA
              einsetzt.
            </p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
              berechtigtes Interesse liegt in der Optimierung der Funktionalität
              unseres Internetauftritts.
            </p>
            <p>
              Durch die so hergestellte Verbindung zu Google kann Google unter
              anderem ermitteln, von welcher Website Ihre Anfrage gesendet
              worden ist, an welche IP-Adresse die Kalenderinformationen zu
              übermitteln sind, den genutzten Browsertyp, Browsersprache und den
              Zeitpunkt des Zugriffs.
            </p>
            <p>
              Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben
              Sie die Möglichkeit, die Installation der Cookies durch die
              entsprechenden Einstellungen in Ihrem Internet-Browser zu
              verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem
              Punkt „Cookies“.
            </p>
            <p>
              Zudem erfolgt die Nutzung von Google&nbsp;Calendar sowie der über
              Google&nbsp;Calendar erlangten Informationen nach den
              Google-Nutzungsbedingungen&nbsp;(
              <a
                href="https://policies.google.com/terms?gl=DE&amp;hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/terms?gl=DE&amp;hl=de
              </a>
              ).
            </p>
            <p>Überdies bietet Google unter</p>
            <p>
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <p>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
            </p>
            <p>weitergehende Informationen an.</p>
            <p>
              Diese Datenschutzerklärung aus Mustertexten mehrerer Quellen
              erstellt:
            </p>
            <ul>
              <li>
                Der Datenschutzerklärungs-Generator von{" "}
                <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>.
              </li>
              <li>
                Der Datenschutzerklärungs-Generator der DGD Deutsche
                Gesellschaft für Datenschutz GmbH, die als{" "}
                <a href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/">
                  Externer Datenschutzbeauftragter Berlin
                </a>{" "}
                tätig ist, in Kooperation mit den{" "}
                <a href="https://www.wbs-law.de/">
                  Datenschutz (DSGVO) Anwälten der Kanzlei WILDE BEUGER SOLMECKE
                  | Rechtsanwälte
                </a>
                .
              </li>
              <li>
                <a
                  href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Die Muster-Datenschutzerklärung
                </a>{" "}
                der{" "}
                <a
                  href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Anwaltskanzlei Weiß &amp; Partner
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
