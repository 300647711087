import React from "react";

import { Link } from "gatsby";

import { Menu } from "../utils/Menu";

import "../styles/bulma_loader.scss";
import "../styles/main.scss";
import "../styles/hero.scss";
import { FooterMenu } from "../utils/FooterMenu";

const Layout = ({ children }) => {
  const [isMenuActive, setMenuActive] = React.useState(false);

  return (
    <>
      <section className="hero is-primary is-medium is-bold">
        <div className="hero-head">
          <nav
            className="navbar"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <a className="navbar-item" href="/">
                <h1
                  className="title is-4"
                  style={{ fontVariant: "small-caps" }}
                >
                  SGI Flieden
                </h1>
              </a>

              <a
                role="button"
                className={`navbar-burger ${isMenuActive ? "is-active" : ""}`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={() => setMenuActive(!isMenuActive)}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div className={`navbar-menu ${isMenuActive ? "is-active" : ""}`}>
              <div className="navbar-start">
                {Object.keys(Menu).map((page) => (
                  <Link key={page} className="navbar-item" to={Menu[page]}>
                    {page}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body has-hero-img">
          <div className="container has-text-centered">
            <h1
              className="title is-size-1-desktop is-size-4-mobile hero-title"
              style={{ fontVariant: "small-caps", hyphens: "manual" }}
            >
              Schützen&shy;gilde König&shy;reich Flieden e.V.
            </h1>
          </div>
        </div>
      </section>
      {
        // Wichtige Nachrichten kann man hier platzieren
        <div className="box cta">
          <p className="has-text-centered">
            <span className="tag is-info mr-2 is-size-4">Neu</span>
            <br className="is-hidden-tablet mb-3" />
            <span className="is-size-4">
              Disziplin Fallplatte ist jetzt mit Kurzwaffe möglich.
            </span>
          </p>
        </div>

        // Otherwise use this spacer:
        // <div className="mt-5" />
      }
      <section className="container">{children}</section>
      <footer className="footer mt-5">
        <div className="container">
          <div className="navbar-menu is-active">
            <div className="navbar-start">
              {Object.keys(Menu).map((page) => (
                <Link key={page} className="navbar-item" to={Menu[page]}>
                  {page}
                </Link>
              ))}
              {Object.keys(FooterMenu).map((page) => (
                <Link key={page} className="navbar-item" to={FooterMenu[page]}>
                  {page}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
