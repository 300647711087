export const Menu = {
  Home: "/",
  Öffnungszeiten: "/#oeffnungszeiten",
  "Schießtermin buchen": "https://sgi-flieden.de/buchen",
  Preisliste: "/Preisliste#title",
  Kalender: "/Kalender#title",
  Standaufsichten: "/Standaufsichten#title",
  Satzung: "/Satzung#title",
  Chronik: "/Chronik#title",
};
